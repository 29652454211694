import { makeStyles } from '@mui/styles';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { OptLayoutProvider } from '@optsol/react';
import { enUS } from 'date-fns/locale';
import { SnackbarProvider } from 'notistack';
import { PropsWithChildren } from 'react';
import { isMobile } from 'react-device-detect';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { TouchBackend } from 'react-dnd-touch-backend';
import { BrowserRouter } from 'react-router-dom';

import { AuthenticationProvider } from '../../contexts/Authentication/authenticationContext';
import { EditCoilHeightsContextProvider } from '../../contexts/EditCoilHeightsContext/EditCoilHeightsContext';
import { EditStowageContextProvider } from '../../contexts/EditStowageContext/EditStowageContext';
import { LSProvider } from '../../contexts/LS.context';
import { RerunContextProvider } from '../../contexts/RerunContext/RerunContext';
import { EditLoadingCoilProvider } from '../../contexts/SectionEditLoadingContext/SectionEditLoadingContext';
import { SignalRProvider } from '../../contexts/SignalrContext/SignalRContext';
import { StaysContextProvider } from '../../contexts/StaysContext/StaysContext';
import { theme } from '../../shared/theme';

// cores serão aplicadas somente com !important
const useStyles = makeStyles(() => ({
  success: { backgroundColor: 'purple' },
  error: { backgroundColor: 'blue' },
  warning: { backgroundColor: 'green' },
  info: { backgroundColor: 'yellow' }
}));

export const AppProviders = ({ children }: PropsWithChildren) => {
  const classes = useStyles();

  return (
    <>
      <SnackbarProvider
        maxSnack={4}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        autoHideDuration={4000}
        classes={{
          variantSuccess: classes.success,
          variantError: classes.error,
          variantInfo: classes.info,
          variantWarning: classes.warning
        }}
      >
        <AuthenticationProvider>
          <BrowserRouter>
            <LSProvider>
              <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={enUS}>
                <RerunContextProvider>
                  <StaysContextProvider>
                    <EditCoilHeightsContextProvider>
                      <EditLoadingCoilProvider>
                        <EditStowageContextProvider>
                          <OptLayoutProvider noRouter theme={theme}>
                            <DndProvider backend={!isMobile ? HTML5Backend : TouchBackend}>
                              <SignalRProvider>{children}</SignalRProvider>
                            </DndProvider>
                          </OptLayoutProvider>
                        </EditStowageContextProvider>
                      </EditLoadingCoilProvider>
                    </EditCoilHeightsContextProvider>
                  </StaysContextProvider>
                </RerunContextProvider>
              </LocalizationProvider>
            </LSProvider>
          </BrowserRouter>
        </AuthenticationProvider>
      </SnackbarProvider>
    </>
  );
};
